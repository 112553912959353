.confirm-bar form {
	
	fieldset  {
			
		
		//border: 1px solid black;
		margin-top: 4px;

		@include media-breakpoint-up(md) {
			float: left;
			width: 74%;
		}
	}


	fieldset .field {
		
		
		margin-right: 20px;

		@include media-breakpoint-up(md) {
			float: left;	

			

			width: 40%;
		}

		label {
			
			margin-right: 8px;
			margin-top: 3px;
			@include media-breakpoint-up(md) {
				float: left;	
			}
		}

		.middleColumn {

			input {
				
				width: 160px;
			}

			@include media-breakpoint-up(lg) {
				float: left;	


			}
		}
	}





	.btn-toolbar {
		
		margin-top: $spacer;

		@include media-breakpoint-up(md) {
			float: right;
			width: 25%;
			margin-top: 0;
		}

		button.btn {
			//border: 1px solid black;
			background-color: #01abd5;
			color: white;
		}
		button.btn:hover {
			
			background-color: #333333;
			color: white;
		}
	}


	.message {
		

		background-color: #56ae31;
		padding: $spacer;
		color: white;
	}


	.field.checkbox,
	.field.readonly {
		
		width: 100%;

		label {

			
			float: none;
			font-size: $font-size-sm;
		}
	}

	.middleColumn span.readonly{
		display: none;
	}
}

.questions {
	padding-top:$spacer/2;
	font-size: $font-size-sm;
}