


header	{
	margin-bottom: $spacer*4;
}

header.header{
  background-color: #343434;
  

}
main {
	//border: 1px solid;

	margin-bottom: 450px;


	@include media-breakpoint-up(md) {
		margin-bottom: 200px;
	}
}
header.header{
/* 	width: 100%;
	background-color: #ffffff; */
	


	@include media-breakpoint-up(md) {
		/* padding: $spacer 0; */
	}

	//margin-bottom: $spacer*4;


	button {

		
		margin-top: $spacer*2;

		background-color: #999;

		@include media-breakpoint-down(sm) {
			display: none;
		}
	}


	button:hover {
		background-color: #ffffff;
		color: #000000;
	}
}

section.confirm-bar {
	//border: 1px solid black;

	width: 100%;
	position: fixed;
	bottom: 0;

	background-color: #e3e3e3;

	padding: $spacer ;



-webkit-box-shadow: 0px -8px 21px 0px rgba(0,0,0,0.34);
-moz-box-shadow: 0px -8px 21px 0px rgba(0,0,0,0.34);
box-shadow: 0px -8px 21px 0px rgba(0,0,0,0.34);
}