/* Variables*/



$green:   		#56ae31 !default;
$green-light: 	#d1f1c4;
$blue:    		#01abd5;

$orange:  		#d8820f;
$black:    		#000000;
$white:    		#ffffff;
$gray-dark: 	#393939;

$gray-light: 	#f0f0f0;

$blue-light:    #d1e1e7;
$blue-dark:    	#00384a;





/* $indigo:  #6610f2 !default;
$purple:  #6f42c1 !default;
$pink:    #e83e8c !default;
$red:     #dc3545 !default;

$yellow:  #ffc107 !default;

$teal:    #20c997 !default;
$cyan:    #17a2b8 !default; */


$enable-rounded:            false;
$enable-shadows:            true;

/* $theme-colors: (
  primary: $blue,
  secondary: $orange,
  success: $green,
  info: $cyan,
  warning: $yellow,
  danger: $red,
  light: $gray,
  dark: $gray-dark
); */

$spacer: 1rem;



// Links

     

$link-color:            $blue;
$link-decoration:       none !default;
$link-hover-color:      $blue;
$link-hover-decoration: underline !default;



// Fonts


$font-size-base: 1rem !default; // Assumes the browser default, typically `16px`
$font-size-lg:   1.3rem !default;
$font-size-sm:   .875rem !default;

//$font-size-icon:   2rem !default;
//$font-family-sans-serif: Arial, sans-serif;

$font-family-sans-serif: 'ProximaNovaSoft-Regular',Arial, sans-serif;
$font-family-serif: Times, serif;
