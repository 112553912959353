@import url("//hello.myfonts.net/count/27aedd");
@import url("//hello.myfonts.net/count/27b5e0");
@font-face {font-family: 'ProximaNovaSoft-Regular';src: url('../webfonts/27AEDD_0_0.eot');src: url('../webfonts/27AEDD_0_0.eot?#iefix') format('embedded-opentype'),url('../webfonts/27AEDD_0_0.woff') format('woff'),url('../webfonts/27AEDD_0_0.ttf') format('truetype');}
@font-face {font-family: 'ProximaNovaSoft-Bold';src: url('../webfonts/27B5E0_0_0.eot');src: url('../webfonts/27B5E0_0_0.eot?#iefix') format('embedded-opentype'),url('../webfonts/27B5E0_0_0.woff') format('woff'),url('../webfonts/27B5E0_0_0.ttf') format('truetype');}


header.header h1 {
  font-size: $font-size-lg;
  text-transform: uppercase;
  margin-top: $spacer*2;
  letter-spacing: 1px;
  color: #fff;
}



header h3 {
  font-weight: bold;
  font-size: $font-size-base;
}

h1 {
 
  font-size: $h1-font-size*0.7;
  margin-bottom: $spacer*1;
  font-weight: bold;
  @include media-breakpoint-up(md) {
    font-size: $h1-font-size*0.8;
    margin-bottom: $spacer*2;
  }
}


h2 {
  font-weight: bold;
  font-size: $h2-font-size*0.6;
  @include media-breakpoint-up(md) {
    font-size: $h2-font-size*0.75;
    //border: 1px solid black;
    margin-bottom: $spacer;
    margin-top: $spacer*2;
  }
}

h3 {
  font-weight: bold;
  font-size: $h3-font-size*0.5;
  @include media-breakpoint-up(md) {
    font-size: $h3-font-size*0.6;
    //border: 1px solid black;
    margin-bottom: $spacer*0.5;
  }
}

h4 {
  font-weight: bold;
  font-size: $h4-font-size*0.5;
  text-transform: uppercase;
  @include media-breakpoint-up(md) {
    font-size: $h4-font-size*0.5;
    //border: 1px solid black;
    margin-bottom: $spacer*0.1;
  }
}

h6 {
  font-weight: bold;
  font-size: $h6-font-size*0.8;
  text-transform: uppercase;
  color: #666666;
}