/*print things*/

@if $enable-print-styles {
	@media print { 


		.confirm-bar .btn, button {
			display: none !important;
		}
		.confirm-bar {

			border-top: 2px solid #666 !important;

	
			position: relative;
			
			bottom: auto;
			background-color: none;

			padding: 0;



		}


	}
}